export const admin = [
  {
    path: '/admin/gifts/show/:id',
    component: () => import('../views/admin/gifts/show.vue'),
  },
  {
    path: '/admin/packagesuser/show/:id',
    component: () => import('../views/admin/packagesuser/show.vue'),
  },
  {
    path: '/',
    component: () => import('../views/admin/layout.vue'),
    // beforeEnter: ifAuthenticated,
    children: [
      {
        path: '/',
        component: () => import('../views/admin/index.vue'),
      },

      // Benutzer
      {
        path: 'users',
        component: () => import('../views/admin/admin.vue'),
        children: [
          // admins
          {
            path: '/',
            component: () => import('../views/admin/admins/index.vue'),
          },
          {
            path: 'add',
            component: () => import('../views/admin/admins/add.vue'),
          },
          {
            path: 'edit/:id',
            component: () => import('../views/admin/admins/add.vue'),
          },
          {
            path: 'days/:id',
            component: () => import('../views/admin/admins/days.vue'),
          },
          {
            path: 'years/:id/:year',
            component: () => import('../views/admin/admins/years.vue'),
          },
        ],
      },

      

      // sections
      {
        path: 'sections',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/sections/index.vue'),
          },
          {
            path: 'add',
            component: () => import('../views/admin/sections/add.vue'),
          },
          {
            path: 'edit/:id',
            component: () => import('../views/admin/sections/add.vue'),
          },
        ],
      },


       // massage
       {
        path: 'massage',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/massage/index.vue'),
          },
          {
            path: 'add',
            component: () => import('../views/admin/massage/add.vue'),
          },
          {
            path: 'edit/:id',
            component: () => import('../views/admin/massage/add.vue'),
          },
        ],
      },

      // massageorder
      {
        path: 'massageorder',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/massageorder/index.vue'),
          },
          {
            path: 'addNew',
            component: () => import('../views/admin/massageorder/addNew.vue'),
          },
          {
            path: 'edit/:id',
            component: () => import('../views/admin/massageorder/add.vue'),
          },
          {
            path: 'editMode/:id',
            component: () => import('../views/admin/massageorder/edit.vue'),
          },
        ],
      },

      // gifts
      {
        path: 'gifts',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/gifts/index.vue'),
          },
          {
            path: 'add',
            component: () => import('../views/admin/gifts/addnew.vue'),
          },
          {
            path: 'edit/:id',
            component: () => import('../views/admin/gifts/add.vue'),
          },
        ],
      },

      // orders
      {
        path: 'orders',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/orders/index.vue'),
          },
          {
            path: 'edit/:id',
            component: () => import('../views/admin/orders/add.vue'),
          },
        ],
      },
      
     
      // packages
      {
        path: 'packages',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/packages/index.vue'),
          },
          {
            path: 'add',
            component: () => import('../views/admin/packages/add.vue'),
          },
          {
            path: 'edit/:id',
            component: () => import('../views/admin/packages/add.vue'),
          },
        ],
      },

      // packagesuser
      {
        path: 'packagesuser',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/packagesuser/index.vue'),
          },
          {
            path: 'add',
            component: () => import('../views/admin/packagesuser/addNew.vue'),
          },
          {
            path: 'edit/:id',
            component: () => import('../views/admin/packagesuser/add.vue'),
          },
        ],
      },

      // blog
      {
        path: 'blog',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/blog/index.vue'),
          },
          {
            path: 'add',
            component: () => import('../views/admin/blog/add.vue'),
          },
          {
            path: 'edit/:id',
            component: () => import('../views/admin/blog/add.vue'),
          },
        ],
      },

      // purchasing
      {
        path: 'purchasing',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/purchasing/index.vue'),
          },
          {
            path: 'add',
            component: () => import('../views/admin/purchasing/add.vue'),
          },
          {
            path: 'edit/:id',
            component: () => import('../views/admin/purchasing/add.vue'),
          },
        ],
      },

      // carts
      {
        path: 'carts',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/carts/index.vue'),
          },
          {
            path: 'add',
            component: () => import('../views/admin/carts/add.vue'),
          },
          {
            path: 'edit/:id',
            component: () => import('../views/admin/carts/add.vue'),
          },
        ],
      },

       // cat_producat
       {
        path: 'cat_producat',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/cat_producat/index.vue'),
          },
          {
            path: 'add',
            component: () => import('../views/admin/cat_producat/add.vue'),
          },
          {
            path: 'edit/:id',
            component: () => import('../views/admin/cat_producat/add.vue'),
          },
          {
            path: 'show/:id',
            component: () => import('../views/admin/cat_producat/show.vue'),
          },
        ],
      },


             // website_producat
             {
              path: 'website_producat',
              component: () => import('../views/admin/admin.vue'),
              children: [
                {
                  path: '/',
                  component: () => import('../views/admin/website_producat/index.vue'),
                },
                {
                  path: 'add',
                  component: () => import('../views/admin/website_producat/add.vue'),
                },
                {
                  path: 'edit/:id',
                  component: () => import('../views/admin/website_producat/add.vue'),
                },
                // {
                //   path: 'show/:id',
                //   component: () => import('../views/admin/website_producat/show.vue'),
                // },
              ],
            },


      // contact
      {
        path: 'contact',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/contact/index.vue'),
          },
        ],
      },

       // supportTickets
       {
        path: 'supportTickets',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/supportTickets/index.vue'),
            
          },
          {
            path: 'show/:id',
            component: () => import('../views/admin/supportTickets/show.vue'),
            
          },
        ],
      },

      // clients
      {
        path: 'clients',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: '/',
            component: () => import('../views/admin/clients/index.vue'),
          },
          {
            path: 'add',
            component: () => import('../views/admin/clients/add.vue'),
          },
          {
            path: 'edit/:id',
            component: () => import('../views/admin/clients/add.vue'),
          },
          {
            path: 'massageorder/:id',
            component: () => import('../views/admin/clients/index2.vue'),
          },


          
        ],
      },

      // clients
      {
        path: 'reports',
        component: () => import('../views/admin/admin.vue'),
        children: [
          {
            path: 'index',
            component: () => import('../views/admin/reports/index.vue'),
          },

          {
            path: 'byday/:day',
            component: () => import('../views/admin/reports/byday.vue'),
          },
          // bymonth
          {
            path: 'bymonth/:from',
            component: () => import('../views/admin/reports/bymonth.vue'),
          },

          // byyear
          {
            path: 'byyear/:from',
            component: () => import('../views/admin/reports/byyear.vue'),
          },

          {
            path: 'massageorder',
            component: () => import('../views/admin/reports/massageorder.vue'),
          },
          // {
          //   path: 'massageorderlist',
          //   component: () =>
          //     import('../views/admin/reports/massageorderlist.vue'),
          // },
          // {
          //   path: 'user',
          //   component: () => import('../views/admin/reports/user.vue'),
          // },
          {
            path: 'editHome',
            component: () => import('../views/admin/reports/editHome.vue'),
          },
          // cartsUsers.vue
          {
            path: 'cartsUsers',
            component: () => import('../views/admin/reports/cartsUsers.vue'),
          },
          {
            path:'carts_items',
            component: () => import('../views/admin/reports/carts_items.vue')
          }
        ],
      },
    ],
  },
  // {
  //   path: 'login',
  //   component: () => import('../views/admin/login.vue'),
  //   beforeEnter: ifNotAuthenticated,
  // },
];
